body{
  margin-bottom:0;
  margin-top:0;

}
.pageGame {
  font-family: 'KCWaxMuseum-Drip', sans-serif;
  color: #800080;
  letter-spacing: 2px;
  margin: auto;
}

.chatContainer {
  display: flex;
  justify-content: center; /* Align Horizontally */
  align-items: center; /* Align Vertically */
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  background-color: #fff;
  max-width: 220px; /* Set the desired fixed width for the chat box */
  max-height: 200px; /* Set the maximum height for the chat box */
  overflow: auto; /* Enable scrolling for the chat box */
  padding: 10px; /* Add padding for better appearance */
  border: 1px solid #ccc; /* Add a border for the chat box */
  border-radius: 4px; /* Add rounded corners */
  position: absolute;
  right: 4%;
  top: 7%;
  margin:0;
}
.chatContainer h3{
  font-family: 'KCWaxMuseum', sans-serif;
  color: #000;
  letter-spacing: 2px;
  margin-bottom: 0;
  margin-top: 0;
}

.logContainer {
  display: flex;
  justify-content: center; /* Align Horizontally */
  align-items: center; /* Align Vertically */
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  background-color: #fff;
  max-width: 220px; /* Set the desired fixed width for the chat box */
  max-height: 200px; /* Set the maximum height for the chat box */
  overflow: auto; /* Enable scrolling for the chat box */
  padding: 10px; /* Add padding for better appearance */
  border: 1px solid #ccc; /* Add a border for the chat box */
  border-radius: 4px; /* Add rounded corners */
  position: absolute;
  right: 4%;
  top: 50%;
}
.logContainer h3{
  font-family: 'KCWaxMuseum', sans-serif;
  color: #000;
  letter-spacing: 2px;
  margin-bottom: 0;
  margin-top: 0;
}

.pageGameTable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.containerGame {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cardDeck img {
  width: 12%;
  display: flex;
  position: absolute;
  left: 20px;
  margin-top: 10%;
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.8);
  cursor:pointer;
}

.playerCards {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content:center;
}

.playerCards h3{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 15px; /* Adjust the font size as needed */
  color: #fff; /* Adjust the text color as needed */
  text-shadow: 2px 4px 4px #000;
  letter-spacing: 2px;
  position: absolute;
  margin-top: -1%;
}


.card-enlarged {
    transform: scale(1.1);
    /* Increase the size by 10% */
    box-shadow: 0 0 15px 0 rgba(255, 255, 255, 1);
    /* Ensure the selected card appears on top of others */
}

.SelectPlayOrDiscardOption h2{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;

}

.SelectPlayOrDiscardOption{
  display: flex;
  justify-content: center; /* Align Horizontally */
  align-items: center; /* Align Vertically */
  position: absolute;
  left: 5%; /* Position on the left */
  top: 50%; /* Position in the middle vertically */
  transform: translateY(-50%);
}

.SelectPlayerButtons{
  display: flex;
  justify-content: center; /* Align Horizontally */
  align-items: center; /* Align Vertically */
  position: absolute;
  left: 2%; /* Position on the left */
  top: 100%; /* Position in the middle vertically */
  transform: translateY(-50%);
}

.SelectPlayerButtons h2{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}

/* Agregar estilo para hacer que el texto sea blanco */
.showResponseCards h2 {
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}

.showWhiskeyCards h2 {
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}

.SelectPlayOrDiscardOption h3 {
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 20px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}

.selectAxeLftRgt h2{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}
.showAddObstacleOption h2{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}
.Revelacion h2{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}

.gameOver h1{
  font-family: 'KCWaxMuseum', sans-serif;
  font-size: 30px;
  color: #ffff;
  text-shadow: 2px 4px 4px #000;
}


.MuiPaper-root{
  font-family: 'KCWaxMuseum', sans-serif !important;
  margin: 0 auto !important;
  min-width: 0px !important;
  background-color:#A62515 !important;
  font-size: 13px !important;
  border-radius:20px !important;
  line-height: 15px !important;
}