.pageList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

}

.ExitButton {
  display: flex;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
  height: 100%;
  /* Ensure that the container takes up the full height */
  width: 100%;
  /* Ensure that the container takes up the full width */
  /*Little more down than the previous component*/
  margin-top: 1%;
}

.header {
  display: flex;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
  height: 100%;
  /* Ensure that the container takes up the full height */
  width: 100%;
  /* Ensure that the container takes up the full width */
  /*Little more down than the previous component*/
  margin-top: 1%;
  margin-bottom: -3%;
  /*Apply Style*/
  font-family: 'KCWaxMuseum-Drip', sans-serif;
  font-size: 40px;
  color: #800080; 
  text-shadow: 2px 4px 4px #000;

}

.gameListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
  height: 100%;
  /* Ensure that the container takes up the full height */
  width: 100%;
  /* Ensure that the container takes up the full width */
  /*Little more down than the previous component*/
  margin-top: 1%;
}

.chatContainer {
  margin-left: 10%;
  /*Apply style*/
  font-family: 'Roboto', sans-serif;
  /*Bigger chat*/
  font-size: 1.2em;
  background-color: #fff;

}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
  height: 100%;
  /* Ensure that the container takes up the full height */
  width: 100%;
  /* Ensure that the container takes up the full width */
  /*Little more down than the previous component*/
  margin-top: 1%;
}

.ChatBox {
  width: "100%";
  /* maxWidth: 360;
  bgcolor: "background.paper"; */
  border: "5px solid #ccc";
}