.title {
    margin-top: 20px;
    font-size: 100px;
    text-align: center;
    color: #800080; 
    text-shadow: 2px 4px 4px #000;
    font-family: 'KCWaxMuseum-Drip', sans-serif; /* Use your custom font or fall back to a system font */
    letter-spacing: 2px;
  }

.centered-formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vh;
    flex: 1
}

.containerFormJoin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}